import {isEmptyObj} from "lib@/util";

/**
 * 当前管理用户的类
 */
class User {
    constructor() {
        this._userData                = null;
        this._flatPermissionList      = {};
        this._localStorageUserDataKey = 'admin_user_data_json';
        this._tokenFrom               = '';
    }

    /**
     * 本地存储的用户数据的key
     */
    get userTokenKey() {
        return this._localStorageUserDataKey;
    }

    get pureUserToken() {
        let token = this.getUserData('token', '');
        token = token.replace(/Bearer /,'');
        return token;
    }

    get userToken() {
        return this.getUserData('token', '');
    }

    set tokenFrom(from) {
        this._tokenFrom = from;
    }

    set userToken(token) {
        let userData            = this.getUserData();
            userData.token      = token;
            userData.token_from = this._tokenFrom;
            this._tokenFrom     = '';
        this.saveUserData(userData);
    }

    saveUserData(userData) {
        this._userData = userData;
        localStorage.setItem(
            this._localStorageUserDataKey,
            JSON.stringify(userData),
        );
        this.storeProjectId();
        return true;
    }


    /**
     * 存储项目ID
     */
    storeProjectId() {
        const userData = this.getUserData();
        if (!userData) return;
        const k = this.getProjectIdStorageKey(this.getUsername())
        if (!k) return;
        localStorage.setItem(k, userData.project_id);
    }

    /**
     * 获取项目ID的存储值
     * @param username {string|undefined}
     * @returns {number|undefined}
     */
    getProjectIdFromStorage(username) {
        const project_id = localStorage.getItem(this.getProjectIdStorageKey(username || this.getUsername()));
        try {
            if (project_id) {
                return parseInt(project_id);
            }
        } catch (e) {
            console.error(e);
        }
    }

    getUsername() {
        const userData = this.getUserData();
        return userData?.['admin_info']?.['username'];
    }

    /**
     * 获取项目ID的存储key
     * @param username
     * @returns {string|undefined}
     */
    getProjectIdStorageKey(username) {
        if (!username) return;
        return `project_id_${username}`;
    }

    clearUserData() {
        this._flatPermissionList = {};
        let userData = this.getUserData();
        let leftData = {
            project_id: userData.project_id,
        };
        return this.saveUserData(leftData);
    }

    getUserData(dataKey = '', defaultData = '') {
        if (!this._userData) {
            let jsonData = localStorage.getItem(this._localStorageUserDataKey) || '';
            this._userData = jsonData ? JSON.parse(jsonData) : {};
        }

        if (dataKey == '') {
            return this._userData;
        }

        return this._userData[dataKey] || defaultData;
    }

    getDefaultRoute() {
        let pageList   = this.getUserData('page_list', []);
        let permission = this._getFirstPermission(pageList);

        if(isEmptyObj(permission)){
            pageList   = this.getUserData('system_menu_list', []);
            permission = this._getFirstPermission(pageList);
        }

        return permission.route || '';
    }

    getFlatPermissionList() {
        if(!isEmptyObj(this._flatPermissionList)){
            return this._flatPermissionList;
        }

        let pageStack = [];
        let pageList = [];

        pageStack.push(this.getUserData('page_list', []));
        pageStack.push(this.getUserData('system_menu_list', []));

        while(pageList = pageStack.pop()){
            for(const page of pageList){
                this._flatPermissionList[page.item.id] = page.item;

                if(page.children.length > 0){
                    pageStack.push(page.children);
                }
            }
        }

        return this._flatPermissionList;
    }

    getCheckoutPermission(permission=''){
        if(permission != ''){
            let permission_list = this.getUserData('permission_list');
            if(permission_list.length >0){
                return permission_list.find(item => {
                    return item  ==  permission
                })  !== undefined;
            }
            return false;
        }

        return false;
    }

    /**
     * 查询当前账号是否有指定页面的权限，并返回菜单的层级关系
     * @param {string} permission 页面的权限名称
     */
    queryMenuAndParent(permission){
        let retData = {
            err   : 1,
            msg   : '没有权限',
            item  : null,
            parent: [],
        }

        let tmpFunc = (pageList) => {
            let item = null;

            for(let page of pageList){
                if(page.item.name == permission){
                    return page.item;
                }

                if(page.children.length > 0){
                    retData.parent.push(page.item);
                    item = tmpFunc(page.children);
                    if(item != null){
                        return item;
                    }
                    else{
                        retData.parent.pop();
                    }
                }
            }

            return item;
        };

        let item = tmpFunc(this.getUserData('page_list', []));

        if(item == null){
            item = tmpFunc(this.getUserData('system_menu_list', []));
        }

        if(item == null){
            return retData;
        }

        retData.err  = 0;
        retData.msg  = 'ok';
        retData.item = item;

        return retData;
    }

    _getFirstPermission(pageList) {
        if (pageList.length < 1) {
            return {};
        }

        for (let k in pageList) {
            let treeNode = pageList[k];

            if (treeNode.item.type == 2) {
                return treeNode.item;
            }

            let tmp = this._getFirstPermission(treeNode.children || []);

            if (!isEmptyObj(tmp)) {
                return tmp;
            }
        }

        return {};
    }

    /**
     * 判断当前是否为录入者
     */
    isNormal(){
        let privilege_bit     = this.getUserData("project_doctor",{}).privilege_bit;
        return (privilege_bit & 4) > 0;
    }

    /**
     * 判断当前是否为协调者
     */
    isSupervisor(){
        let privilege_bit     = this.getUserData("project_doctor",{}).privilege_bit;
        return (privilege_bit & 32) > 0;
    }

    /**
     * 判断当前是否为第三方监查员
     */
     isMonitoring(){
        let privilege_bit     = this.getUserData("project_doctor",{}).privilege_bit;
        return (privilege_bit & 64) > 0;
    }

    /**
     * 判断当前是否为执委员专家
     */
    isAgent(){
        let privilege_bit     = this.getUserData("project_doctor",{}).privilege_bit;
        return (privilege_bit & 256) > 0;
    }

    /**
     * 判断当前是否为研究者
     */
     isResearcher(){
        let flag = this.getUserData("admin_info",{}).flag;
        return flag =='s2copd_researcher';
    }
    
    /**
     * 判断当前是否为系统管理员
     */
     isRoot(){
        let flag = this.getUserData("admin_info",{}).flag;
        return flag =='s2copd_manager';
    }
    isNormalv2(){
        let privilege_bit     = this.getUserData("project_doctor",{}).privilege_bit;
        return (privilege_bit & 4 ) == 4;
    }

    /**
     * 判断当前是否为数据管理员
     */
    isDataManager(){
        let privilege_bit     = this.getUserData("project_doctor",{}).privilege_bit;
        return (privilege_bit & 16) > 0;
    }

    /**
     * 判断当前是否为启动辅助医生
     */
    isQiDongAssist(){
        let privilege_bit     = this.getUserData("project_doctor",{}).privilege_bit;
        return (privilege_bit & 128) > 0;
    }
}

export default new User();
